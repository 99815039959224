<template>
    <div class="main-container">
      <h1 class="main-heading">
        <div>{{ $t("GAME_CONSTANTS") }}</div>
      </h1>
      <div class="content-container">
        <div class="col">
            <div class="form-container">
                <p>{{`Maximum Watch Ad Count`}}</p>
                <input class="input" type="number" v-model="gameConstantsObj.maxAdCount"/>
            </div>
            <div class="form-container">
                <p> Match End Survey Min Trophy</p>
                <input class="input" type="number" v-model="gameConstantsObj.surveyMinTrophy"/>
            </div> 
            <div class="form-container">
                <p>Match End Survey Max Trophy</p>
                <input class="input" type="number" v-model="gameConstantsObj.surveyMaxTrophy"/>
            </div>
            <div class="form-container">
                <p>Test User Critical Damage Range</p>
                <input class="input" type="number" v-model="gameConstantsObj.critDamageRange"/>
            </div>
            <p>{{`Supported Languages`}}</p>
            <multiselect
              v-model="gameConstantsObj.supportedLanguages"
              :options="languageOptions"
              :multiple="true"
              :close-on-select="false"
              :clear-on-select="false"
              :preserve-search="true"
              placeholder="Select Language"
              :preselect-first="false"
              label="name"
              track-by="name"
            >
              <template v-slot:selection="{ values, isOpen }"
                ><span
                  class="multiselect__single"
                  v-if="values.length &amp;&amp; !isOpen"
                  >{{ values.length }} language selected</span
                ></template
              >
            </multiselect>
        <div class="form-container">
            <Button text="Save" :loading="isLoading" :onClick="openConfirm" type="info" size="sm"/>
        </div>
      </div>
      </div>
          <ConfirmPopup :popup-open="popupOpen" :text="popupText" @popup-close="popupOpen=false" :type="popupType" :changes="changes" @confirm="submit"/>

    </div>
  </template>
  
  <script>
  import { watch, computed, ref, onBeforeUnmount, onMounted } from "vue";
  import { useStore } from "vuex";
  import "v-calendar/dist/style.css";
  
  import Button from '../../components/common/Button.vue'
  import configSchema from "../../schemas/config";
  import configConstants from "@/constants/configConstants";
  import languageConstants from "@/constants/languagesTemp";
  import { io } from "socket.io-client";
  import Multiselect from "vue-multiselect";
  import ConfirmPopup from '../../components/common/ConfirmPopup.vue'
  export default {
    name: "GameConstants",
    components: {
      Multiselect,
      Button,
      ConfirmPopup
    },
    setup() {
      const store = useStore();
      const socket = io(process.env.VUE_APP_BACKEND_URL);
      const popupOpen = ref(false);
      const popupTexts = ["This page is edited by another user. Please refresh before making changes.", "Your changes: "];
      const popupText = ref(popupTexts[0])
      const popupType = ref("refresh")
      const changes = ref([])
      const languageOptions = ref([]);
      languageOptions.value = languageConstants.languages;
      window.firstGameConstants = null;
      store.dispatch('resources/getResources');
      const serverConfig = computed(
        () => store.getters['resources/getResources']
      );
      const gameConstantsObj = ref({});
      const isLoading = ref(false)
      watch(
        serverConfig, value => {
            gameConstantsObj.value = { ...value};
            window.firstGameConstants = JSON.parse(JSON.stringify(gameConstantsObj.value))
        },
        { immediate: true }
      );
  
      const submit = () => {
        store.dispatch('resources/updateResourceFields', { maxAdCount: gameConstantsObj.value.maxAdCount,
                                                          supportedLanguages: gameConstantsObj.value.supportedLanguages,
                                                          surveyMinTrophy: gameConstantsObj.value.surveyMinTrophy,
                                                          surveyMaxTrophy: gameConstantsObj.value.surveyMaxTrophy}).then(() => {
          socket.emit("update", {
            socketId: socket.id,
            itemId: "Game Constants",
            page: "Game Constants",
            data: gameConstantsObj.value
          });
          popupOpen.value = false;
          changes.value = [];
        })
      };
      socket.on('updated', (data) => {
        if (data.socketId != socket.id && data.page === "Game Constants" && data.data){
          popupOpen.value = true;
          popupType.value = "refresh";
          popupText.value = popupTexts[0];
          changes.value.push(("Maximum Watch Ad Count: " + gameConstantsObj.value.maxAdCount + " => " + data.data["maxAdCount"]))
          changes.value.push(("Supported Languages: " + gameConstantsObj.value.supportedLanguages + " => " + data.data["supportedLanguages"]))
        }
      })

      const openConfirm = () => {
        popupType.value = "confirm";
        popupOpen.value = true;
        popupText.value = popupTexts[1];
        changes.value.push(("Maximum Watch Ad Count: " + window.firstGameConstants.maxAdCount + " => " + gameConstantsObj.value.maxAdCount))
      }
      onMounted(() => store.dispatch("loader/loadingStatus", false));
      onBeforeUnmount(() => store.dispatch("loader/loadingStatus", true));
      const appConfig = computed(() => store.getters["auth/getAppConfig"]);
      const env = appConfig.value.appEnv || "dev";
  
      return {
        gameConstantsObj,
        configSchema,
        submit,
        env,
        configConstants,
        isLoading,
        popupOpen,
        popupText,
        popupType,
        changes,
        openConfirm,
        languageOptions
      };
    },
  };
  </script>
  <style src="vue-multiselect/dist/vue-multiselect.css"></style>
  <style scoped>
  .main-container {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0 30px;
  }
  
  .content-container {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  .row-title {
    width: 40%;
    text-align: start;
  }
  
  .content-container.sub-container {
    border: 1px solid black;
  }
  .col {
  padding: 15px;
}
.form-container p {
  width: 60%;
  text-align: start;
}
.form-container {
    margin: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
  .main-heading {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  </style>